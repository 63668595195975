import { put, call, takeEvery, fork } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';
import adminBffClient from 'services/admin-bff-client';
import playlistsClient from 'services/playlists-client';
import { Notification } from '@schibsted-svp/react-ui';
import { isPlaylistOfPlaylists } from 'screens/NewsroomScreen/PlaylistsScreen/utils';

import {
    PLAYLISTS_FETCH,
    PLAYLISTS_UPDATE,
    PLAYLISTS_CREATE,
    PLAYLISTS_DELETE,
    playlistsFetchSuccess,
    playlistsFetchError,
    playlistsCreateSuccess,
    playlistsUpdateSuccess,
    playlistsAssetsFetchSuccess,
    playlistsDeleteSuccess,
    playlistsDeleteError,
    playlistsUpdateError,
    playlistsCreateError,
    playlistsFetch,
    playlistOfPlaylistsItemsFetchSuccess,
    playlistsGeneratorFetchError,
} from 'store/playlists/items';

export function* createPlaylist({
    payload: { provider, name, header, labels, searchQuery, metadata, items, assets },
    meta,
}) {
    try {
        const { playlist, success } = yield call(adminBffClient.createPlaylist, {
            provider,
            name,
            header,
            labels,
            searchQuery,
            metadata,
            items,
        });

        if (success) {
            yield put(playlistsCreateSuccess({ provider, playlist: { ...playlist, assets }, meta }));
            return;
        }
        yield put(playlistsCreateError({ meta }));
    } catch (error) {
        yield put(playlistsCreateError({ meta }));
        reportMessageToSentry({
            message: 'Failed to create playlist',
            extras: {
                error,
            },
        });
    }
}

export function* updatePlaylist({
    payload: { id, provider, name, header, labels, searchQuery, metadata, items, assets },
    meta,
}) {
    try {
        const { playlist, success } = yield call(adminBffClient.updatePlaylist, {
            id,
            provider,
            name,
            header,
            labels,
            searchQuery,
            metadata,
            items,
        });
        if (success) {
            yield put(playlistsUpdateSuccess({ provider, playlist: { ...playlist, assets }, meta }));
            return;
        }
        yield put(playlistsUpdateError({ meta }));
    } catch (error) {
        yield put(playlistsUpdateError({ meta }));
        reportMessageToSentry({
            message: 'Failed to update playlist',
            extras: {
                error,
            },
        });
    }
}

export function* fetchAssetsForSinglePlaylist({ playlist, provider, staticAssets, originProvider }) {
    try {
        const { resolved } = yield call(playlistsClient.getResolvedPlaylist, { provider, id: playlist.id });

        yield put(
            playlistsAssetsFetchSuccess({
                assets: resolved,
                provider,
                playlistId: playlist.id,
                staticAssets,
                originProvider,
            })
        );
    } catch (error) {
        yield put(playlistsFetchError({ provider, id: playlist.id }));
    }
}

export function* fetchPlaylistsAssetsController({ playlists, provider: originProvider }) {
    try {
        for (const playlist of playlists) {
            const staticAssets = playlist.items.map(({ position, asset }) => ({
                id: asset.id,
                position,
            }));

            yield fork(fetchAssetsForSinglePlaylist, {
                playlist,
                provider: playlist.provider,
                staticAssets,
                originProvider,
            });
        }
    } catch (error) {
        const message = 'Cannot fetch playlist items';
        reportMessageToSentry({
            message,
            extras: {
                error,
            },
        });
        Notification.notify.error(message);
    }
}

export function* fetchPlaylistsController({ playlists, provider }) {
    try {
        for (const playlist of playlists) {
            for (const item of playlist.items) {
                yield put(
                    playlistsFetch({
                        provider: item.playlist.provider,
                        id: item.playlist.id,
                        parentPlaylistId: playlist.id,
                        parentPlaylistProvider: provider,
                    })
                );
            }
        }
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch playlists',
            extras: {
                error,
            },
        });
        yield put(playlistsGeneratorFetchError({ provider, playlists }));
    }
}

export function* fetchPlaylists({ payload: { provider, name, id, parentPlaylistId, parentPlaylistProvider } }) {
    try {
        const playlist = yield call(adminBffClient.fetchPlaylists, {
            provider,
            name,
            id,
        });

        if (!playlist.length) {
            yield put(playlistsFetchSuccess({ provider, playlists: playlist, id }));

            return;
        }
        const isPoP = isPlaylistOfPlaylists(playlist[0]);

        if (isPoP) {
            yield call(fetchPlaylistsController, { playlists: playlist, provider });
        } else {
            yield call(fetchPlaylistsAssetsController, {
                playlists: playlist,
                provider: parentPlaylistProvider,
                parendId: parentPlaylistId,
            });

            if (parentPlaylistId) {
                yield put(
                    playlistOfPlaylistsItemsFetchSuccess({ provider: parentPlaylistProvider, id: parentPlaylistId })
                );
            }
        }

        yield put(playlistsFetchSuccess({ provider, playlists: playlist, id }));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch playlists',
            extras: {
                error,
            },
        });
        yield put(playlistsFetchError({ provider, id }));
    }
}

export function* deletePlaylist({ payload: { id, provider } }) {
    try {
        const playlist = yield call(adminBffClient.deletePlaylist, {
            id,
            provider,
        });

        if (playlist.success) {
            yield put(playlistsDeleteSuccess({ id, provider }));
            Notification.notify.success('Playlist was deleted successfully');
        } else {
            yield put(playlistsDeleteError({ id, provider }));
            Notification.notify.error('There was an error during playlist removal');
        }
    } catch (error) {
        const message = 'There was an error during playlist removal';
        yield put(playlistsDeleteError({ id, provider }));
        reportMessageToSentry({
            message,
            extras: {
                error,
            },
        });
        Notification.notify.error(message);
    }
}

export default [
    takeEvery(PLAYLISTS_FETCH, fetchPlaylists),
    takeEvery(PLAYLISTS_CREATE, createPlaylist),
    takeEvery(PLAYLISTS_UPDATE, updatePlaylist),
    takeEvery(PLAYLISTS_DELETE, deletePlaylist),
];
