import { get } from 'lodash';
import { GetPlaylists, CreatePlaylist, UpdatePlaylist, DeletePlaylist } from './graphql/playlists.graphql';

/**
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.provider
 * @param {String} params.name
 * @param {Number} params.limit
 * @param {Number} params.page
 * @returns {Promise<Array>}
 */
export async function fetchPlaylists({ id, provider, name, labels, limit = 10, page = 1 }) {
    const response = await this.query(GetPlaylists, {
        id,
        provider,
        name,
        labels,
        limit,
        page,
    });
    return get(response, 'providerPlaylists', []);
}

/**
 * @param {Object} params
 * @param {String} params.provider
 * @param {String} params.name
 * @param {String} params.searchQuery
 * @param {Array} params.items
 * @returns {Promise<Array>}
 */
export async function createPlaylist({ provider, name, header, labels, searchQuery, metadata, items }) {
    const response = await this.query(CreatePlaylist, {
        provider,
        name,
        header,
        labels,
        searchQuery,
        metadata,
        items,
    });
    return get(response, 'createPlaylist');
}

/**
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.provider
 * @param {String} params.name
 * @param {String} params.searchQuery
 * @param {Array} params.items
 * @returns {Promise<Array>}
 */
export async function updatePlaylist({ id, provider, name, header, labels, searchQuery, metadata, items }) {
    const response = await this.query(UpdatePlaylist, {
        id,
        provider,
        name,
        header,
        labels,
        searchQuery,
        metadata,
        items,
    });
    return get(response, 'updatePlaylist');
}

/**
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.provider
 * @returns {Promise<Array>}
 */
export async function deletePlaylist({ id, provider }) {
    const response = await this.query(DeletePlaylist, {
        id,
        provider,
    });
    return get(response, 'deletePlaylist');
}
