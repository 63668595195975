import { Button, Dropdown } from '@schibsted-svp/react-ui';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';

type UploadDialogTriggerProps = {
    triggerType: 'button' | 'dropdown';
    toggleDialog: () => void;
};

export function UploadDialogTrigger({ triggerType, toggleDialog }: UploadDialogTriggerProps) {
    const userPermissions = useUserPermissions();
    const disabled = !userPermissions?.isUser;

    if (triggerType === 'dropdown') {
        return (
            <Dropdown.Item hideOnOutsideClick itemKey="upload" onClick={toggleDialog} disabled={disabled}>
                Create asset
            </Dropdown.Item>
        );
    }

    return (
        <Button type="button" variant="standard" onClick={toggleDialog} disabled={disabled}>
            Create asset
        </Button>
    );
}
