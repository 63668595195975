import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import config from 'config';
import authToken from 'models/authToken';

const svpApiProxyBaseQuery = fetchBaseQuery({
    baseUrl: `${config.apiProxy.host}${config.apiProxy.path}`,
    prepareHeaders: (headers) => {
        const token = authToken.get();
        if (token?.raw) {
            headers.set('Authorization', `Bearer ${token.raw}`);
        }
    },
});

export const svpApiProxy = createApi({
    reducerPath: 'svpApiProxy',
    baseQuery: svpApiProxyBaseQuery,
    endpoints: () => ({}),
});
