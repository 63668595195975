import { useSelector } from 'react-redux';
import { MdAccessTime, MdRemoveRedEye } from 'react-icons/md';
import { CopyToClipboard } from '@schibsted-svp/react-ui';
import { getFormattedTime } from 'lib/time';
import { format as formatNumber } from 'lib/number';
import { getAsset } from 'store/assets/selectors';
import AssetPreview from 'components/video/AssetPreview';
import { thumbnailSizes } from 'components/core/VideoCard/helpers';
import ConditionalLink from 'components/core/ConditionalLink';
import css from './AssetTile.module.scss';

type AssetTileProps = {
    provider: string;
    assetId: number;
    onCopyIdClick: () => void;
    showDisplays?: boolean;
    noFollow?: boolean;
};

export function AssetTile({
    provider,
    assetId,
    onCopyIdClick,
    showDisplays = false,
    noFollow = false,
}: AssetTileProps) {
    const asset = useSelector((state) => getAsset(state, { id: assetId, provider }));

    return (
        <ConditionalLink to={!noFollow && `/${asset.provider}/assets/${asset.id}`} className={css.item}>
            <AssetPreview asset={asset} width={thumbnailSizes.compact} className={css.itemPreview} />
            <CopyToClipboard className={css.itemId} onClick={onCopyIdClick} text={asset.id} title="Click to copy ID" />
            <div className={css.itemAdditional}>
                {showDisplays && asset.displays && (
                    <span>
                        <MdRemoveRedEye color={css.silver2} />
                        {formatNumber(asset.displays)}
                    </span>
                )}
                <span>
                    <MdAccessTime color={css.silver2} />
                    {getFormattedTime(asset.duration)}
                </span>
            </div>
        </ConditionalLink>
    );
}
