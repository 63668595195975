import type { UseFieldArrayRemove } from 'react-hook-form';
import { MdAdd, MdClose } from 'react-icons/md';
import { Button } from '@schibsted-svp/react-ui';
import Playlist from 'components/Playlists';
import Tooltip from 'components/ui/Tooltip';
import { MovablePlaylistItem } from './MovablePlaylistItem';
import type { PlaylistItemPlaylist } from './types';

import css from './PlaylistOfPlaylistItem.module.scss';

interface PlaylistOfPlaylistItemProps {
    index: number;
    field: PlaylistItemPlaylist;
    openAddItemModal: (index: number) => void;
    remove: UseFieldArrayRemove;
    moveItem: (fromIndex: number, toIndex: number) => void;
    isLastItem: boolean;
}

export function PlaylistOfPlaylistItem({
    index,
    field,
    openAddItemModal,
    remove,
    moveItem,
    isLastItem,
}: PlaylistOfPlaylistItemProps) {
    const { playlist, isStatic } = field;

    return (
        <MovablePlaylistItem id={playlist.id} index={index} moveItem={moveItem}>
            <Playlist
                provider={playlist.provider}
                id={playlist.id}
                hideDeleteButton
                className={css.playlistCard}
                size="compact"
                linkOnlyOnName
            >
                <Button
                    size="small"
                    className={css.addButtonTop}
                    iconOnly
                    type="button"
                    onClick={() => {
                        openAddItemModal(index);
                    }}
                >
                    <MdAdd size={25} />
                </Button>
                {isLastItem && (
                    <Button
                        size="small"
                        className={css.addButtonBottom}
                        iconOnly
                        type="button"
                        onClick={() => {
                            openAddItemModal(index + 1);
                        }}
                    >
                        <MdAdd size={25} />
                    </Button>
                )}
                {isStatic && (
                    <Tooltip theme="light" content="Remove" placement="top" offset={[0, 12]}>
                        <div>
                            <Button
                                type="button"
                                variant="standard"
                                size="small"
                                iconOnly
                                onClick={() => remove(index)}
                                className={css.removeButton}
                            >
                                <MdClose />
                            </Button>
                        </div>
                    </Tooltip>
                )}
            </Playlist>
        </MovablePlaylistItem>
    );
}
