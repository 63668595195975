import { DEFERRED } from 'store/deferred';

export const CREATE_VIDEO = 'Create/VIDEO';
export const CREATE_VIDEO_WITHOUT_FILE = 'Create/VIDEO_WITHOUT_FILE';
export const UPLOAD_VIDEO = 'Upload/VIDEO';
export const UPLOAD_VIDEO_PROGRESS = 'Upload/VIDEO_PROGRESS';
export const UPLOAD_VIDEO_COMPLETE = 'Upload/VIDEO_COMPLETE';
export const UPLOAD_VIDEO_ERROR = 'Upload/VIDEO_ERROR';
export const UPLOAD_CANCEL = 'Upload/CANCEL';
export const TRANSCODE_VIDEO = 'Transcode/VIDEO';
export const TRANSCODE_VIDEO_PROGRESS = 'Transcode/VIDEO_PROGRESS';
export const TRANSCODE_VIDEO_COMPLETE = 'Transcode/VIDEO_COMPLETE';
export const TRANSCODE_VIDEO_FAILURE = 'Transcode/VIDEO_FAILURE';
export const UPLOAD_VIDEO_POSTER = 'Upload/VIDEO_POSTER';
export const UPLOAD_VIDEO_POSTER_COMPLETE = 'Upload/VIDEO_POSTER_COMPLETE';
export const UPLOAD_VIDEO_POSTER_ERROR = 'Upload/VIDEO_POSTER_ERROR';
export const REVOKE_VIDEO_POSTER_PREVIEW = 'Revoke/VIDEO_POSTER_PREVIEW';

/**
 * @param {String} provider
 * @param {File} file
 * @param {Object} options
 */
export const createVideo = (provider, file, options) => ({
    type: CREATE_VIDEO,
    provider,
    file,
    options,
    [DEFERRED]: true,
});

/**
 * @param {String} provider
 * @param {String} url
 * @param {Object} options
 */
export const createVideoFromUri = (provider, uri, options) => ({
    type: CREATE_VIDEO,
    provider,
    uri,
    options,
    [DEFERRED]: true,
});

/**
 * @param {String} provider
 * @param {String} url
 */
export const createVideoWithoutFile = (provider, fileType) => ({
    type: CREATE_VIDEO_WITHOUT_FILE,
    provider,
    fileType,
    [DEFERRED]: true,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {File} file
 * @param {Object} options
 */
export const uploadVideo = (provider, id, file, options) => ({
    type: UPLOAD_VIDEO,
    provider,
    id,
    file,
    options,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {Object} filename
 */
export const cancelUpload = (provider, id, filename) => ({
    type: UPLOAD_CANCEL,
    provider,
    id,
    filename,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {Number} progress
 * @param {Number} secondsLeft
 * @param {Strong} filename
 */
export const videoUploadProgress = (provider, id, progress, secondsLeft, filename) => ({
    type: UPLOAD_VIDEO_PROGRESS,
    provider,
    id,
    progress,
    secondsLeft,
    filename,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {String} uri
 * @param {Object} options
 */
export const completeVideoUpload = (provider, id, uri, options) => ({
    type: UPLOAD_VIDEO_COMPLETE,
    provider,
    id,
    uri,
    options,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {File} file
 * @param {String|Error} error
 */
export const videoUploadError = (provider, id, file, error) => ({
    type: UPLOAD_VIDEO_ERROR,
    provider,
    id,
    file,
    error,
});

/**
 * @param {String} provider
 * @param {Number} id
 */
export const transcodeVideo = (provider, id) => ({
    type: TRANSCODE_VIDEO,
    provider,
    id,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {Number} progress
 */
export const videoTranscodingProgress = (provider, id, progress) => ({
    type: TRANSCODE_VIDEO_PROGRESS,
    provider,
    id,
    progress,
});

/**
 * @param {String} provider
 * @param {Number} id
 */
export const completeVideoTranscoding = (provider, id) => ({
    type: TRANSCODE_VIDEO_COMPLETE,
    provider,
    id,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {String} [error]
 */
export const videoTranscodingFailure = (provider, id, error) => ({
    type: TRANSCODE_VIDEO_FAILURE,
    provider,
    id,
    error,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {File} file
 */
export const uploadVideoPoster = (provider, id, file, imageType) => ({
    type: UPLOAD_VIDEO_POSTER,
    provider,
    id,
    file,
    imageType,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {String} uri
 */
export const completeVideoPosterUpload = (provider, id, uri, imageType) => ({
    type: UPLOAD_VIDEO_POSTER_COMPLETE,
    provider,
    id,
    uri,
    imageType,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {File} file
 * @param {String|Error} error
 */
export const videoPosterUploadError = (provider, id, file, error) => ({
    type: UPLOAD_VIDEO_POSTER_ERROR,
    provider,
    id,
    file,
    error,
});

/**
 * @param {String} provider
 * @param {Number} id
 * @param {String} imageType
 */
export const revokeVideoPosterPreview = (provider, id, imageType) => ({
    type: REVOKE_VIDEO_POSTER_PREVIEW,
    provider,
    id,
    imageType,
});
