import config from 'config';
import { reportMessageToSentry } from 'lib/error';
import { PlaylistItemPlaylist, PlaylistItemAsset, PlaylistLabel, Playlist, PlaylistOfPlaylists } from './types';

const { playlists } = config;

export const LABEL_TYPE = 'internal';

export const LABELS = Object.freeze({
    VIDEO: 'video',
    AUDIO: 'audio',
    PODCAST: 'podcast',
    POP: 'playlist-of-playlists',
});

type LabelProps = {
    label: string;
    style: {
        color: string;
        backgroundColor: string;
    };
    value: string;
};

type UnpositionedPlaylistAsset = {
    isStatic: boolean;
    asset: {
        id: number;
        provider: string;
        duration: number;
        category: number;
    };
};

type UnpositionedPlaylistPlaylist = {
    isStatic: boolean;
    playlist: {
        id: number;
        provider: string;
    };
};

type UnpositionedPlaylistItems = Array<UnpositionedPlaylistAsset | UnpositionedPlaylistPlaylist>;

const PREFERRED_PLAYLIST_TYPE_KEY = 'svpPreferredPlaylistType';

export const mapToBackendPlaylistItemsNotation = (items: UnpositionedPlaylistItems) =>
    items
        .map((item, index) => ({ ...item, position: index }))
        .filter(({ isStatic }) => isStatic)
        .map((item) => {
            if ('asset' in item) {
                const { position, asset } = item;
                return { position, asset: { id: asset.id, provider: asset.provider } };
            }

            const { position, playlist } = item;
            return { position, playlist };
        });

export const getSearchQueryResult = (items: UnpositionedPlaylistAsset[]) =>
    items.filter(({ isStatic }) => !isStatic).map(({ asset }) => ({ id: asset.id, provider: asset.provider }));

export const mergePlaylistLabels = (labels: string[], type: string, isPodcastPlaylist: boolean) => {
    const adjustedType = isPodcastPlaylist ? LABELS.PODCAST : type;
    const typeAsLabel = { id: adjustedType, name: adjustedType, type: LABEL_TYPE };

    if (!labels) {
        return [typeAsLabel];
    }
    const fullLabelsStructure = labels.map((label) => ({
        id: label,
        name: playlists.labels?.find((singleLabel) => singleLabel.value === label)?.label,
        type: '',
    }));

    return [typeAsLabel, ...fullLabelsStructure];
};

export const isLabelInternal = (label: PlaylistLabel) => label.type === LABEL_TYPE;

export const getPlaylistType = (playlist: Playlist) => {
    const { labels } = playlist;

    if (!labels?.length) {
        return LABELS.VIDEO;
    }
    const typeId = labels.find(isLabelInternal)?.id;

    return mapPlaylistType(typeId) || LABELS.VIDEO;
};

export const isPodcastOnlyPlaylist = (playlist: Playlist) => {
    if (getPlaylistType(playlist) === LABELS.AUDIO) {
        return Boolean(playlist.labels.find(({ id }) => id === LABELS.PODCAST)?.id);
    }
    return false;
};

export const getPlaylistLabels = (playlist: Playlist) => {
    const { labels } = playlist;

    if (!labels?.length) {
        return [];
    }

    const notInternalLabels = labels.reduce((labelsWithoutType, currentLabel) => {
        if (isLabelInternal(currentLabel)) {
            return labelsWithoutType;
        }

        return [currentLabel.id, ...labelsWithoutType];
    }, []);

    return notInternalLabels;
};

export const mergePlaylistLabelsIds = (labels: LabelProps[], type: string) => {
    if (!labels) {
        return type;
    }
    const labelsIds = labels.map((label) => label.value);

    return [type, ...labelsIds];
};
export const isPlaylistOfPlaylists = (playlist: Playlist): playlist is PlaylistOfPlaylists => {
    return playlist.labels.some((label) => label.id === LABELS.POP);
};

export const setPreferredPlaylistType = (playlistType: string) => {
    try {
        window.localStorage.setItem(PREFERRED_PLAYLIST_TYPE_KEY, playlistType);
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to set preferred asset list type',
            extras: {
                error,
            },
        });
    }
};

export const getPreferredPlaylistType = () => {
    try {
        return mapPlaylistType(window.localStorage.getItem(PREFERRED_PLAYLIST_TYPE_KEY)) || LABELS.VIDEO;
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to get preferred asset list type',
            extras: {
                error,
            },
        });
        return LABELS.VIDEO;
    }
};

export const mapPlaylistType = (playlistType: string) =>
    playlistType === LABELS.PODCAST ? LABELS.AUDIO : playlistType;

export const createPlaylistFormName = (mode: 'new' | 'edit') => `playlist-form-filters-${mode}`;

export const adjustItemsIndexes = (items: PlaylistItemAsset[] | PlaylistItemPlaylist[]) =>
    items.map((item, index) => ({ ...item, position: index }));

export const defaultFilters = () => {
    const defaultAssetType = getPreferredPlaylistType();
    const { status } = config.asset.labels;

    return {
        statuses: [{ value: 'active', label: status.active }],
        assetTypes: [{ value: defaultAssetType, label: defaultAssetType }],
        isPodcast: false,
    };
};

export const getPlaylistMetadata = (playlist: Playlist): { key: string; value: string }[] => {
    return Object.entries(playlist.metadata || {}).map(([key, value]) => ({ key, value: String(value) }));
};

export const mapPlaylistMetadata = (metadata: { key: string; value: string }[]): string => {
    if (!metadata?.length) {
        return null;
    }
    return JSON.stringify(Object.fromEntries(metadata?.map(({ key, value }) => [key, value]) || []));
};
