import { type ReactNode, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import classnames from 'classnames/bind';
import css from './MovablePlaylistItem.module.scss';

const cln = classnames.bind(css);

type DragItem = {
    id: number | string;
    index: number;
};

const PLAYLIST_ITEM_TYPE = 'PLAYLIST_ITEM';

type MovablePlaylistItemProps = {
    children: ReactNode;
    id: number | string;
    index: number;
    moveItem: (fromIndex: number, toIndex: number) => void;
    disabled?: boolean;
};

export function MovablePlaylistItem({ children, id, index, moveItem, disabled = false }: MovablePlaylistItemProps) {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag({
        type: PLAYLIST_ITEM_TYPE,
        item: { id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: !disabled,
    });

    const [, drop] = useDrop<DragItem>({
        accept: PLAYLIST_ITEM_TYPE,
        hover(item) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveItem(dragIndex, hoverIndex);

            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
    });

    drag(drop(ref));

    return (
        <div ref={ref} className={cln('wrapper', { isDragging, disabled })}>
            {children}
        </div>
    );
}
