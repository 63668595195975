import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Notification } from '@schibsted-svp/react-ui';
import { createVideo, createVideoFromUri, createVideoWithoutFile } from 'store/video/upload/actions';
import { isAudioOrVideoType } from 'lib/file';
import { TABS } from './UploadDialog';

const uploadFormSchema = z
    .object({
        files: z
            .any()
            .optional()
            .refine((files) => files?.every((file: File) => isAudioOrVideoType(file)) ?? true, 'Unsupported file(s)'),
        fileUrl: z.union([z.literal(''), z.string().url()]).optional(),
        overlay: z.string().optional(),
        fileType: z.string().optional(),
        activeTab: z.string().optional(),
    })
    .passthrough()
    .superRefine(({ files, fileUrl, activeTab }, ctx) => {
        if (activeTab === TABS.LOCAL && !files) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['files'],
                message: 'Field required',
            });
        }
        if (activeTab === TABS.URL && !fileUrl) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['fileUrl'],
                message: 'Field required',
            });
        }
    });

type UploadFormData = {
    files: File[];
    fileUrl: string;
    overlay: string;
    fileType: string;
};

export const useUploadDialogForm = ({ provider, toggleDialog }: { provider: string; toggleDialog: () => void }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const formApi = useForm<UploadFormData>({
        resolver: zodResolver(uploadFormSchema),
        defaultValues: { fileType: 'video', activeTab: TABS.LOCAL },
    });

    const handleSubmit = formApi.handleSubmit(async (data) => {
        try {
            let uploadedId;

            if (data.files) {
                const ids = await Promise.all(
                    data.files.map((file) => dispatch(createVideo(provider, file, { overlayUrl: data.overlay })))
                );
                uploadedId = ids.find(Number.isInteger);
            } else if (data.fileUrl) {
                uploadedId = await dispatch(createVideoFromUri(provider, data.fileUrl, { overlayUrl: data.overlay }));
            } else if (data.fileType) {
                uploadedId = await dispatch(createVideoWithoutFile(provider, data.fileType));
            }

            if (uploadedId) {
                history.push(`/${provider}/assets/${uploadedId}`);
            }

            toggleDialog();
        } catch {
            Notification.notify.error('An unexpected error occurred.');
        }
    });

    return { formApi, handleSubmit };
};
