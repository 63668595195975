import { useState } from 'react';
import { useFieldArray, type UseFormReturn, type UseFormHandleSubmit } from 'react-hook-form';
import { MdAdd } from 'react-icons/md';
import { Button, Dialog, Input, InputError } from '@schibsted-svp/react-ui';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import type { FormValues } from './types';
import css from './PlaylistMetadataDialog.module.scss';

type PlaylistMetadataDialogProps = {
    formApi: UseFormReturn<FormValues>;
    handleSubmit: ReturnType<UseFormHandleSubmit<FormValues>>;
};

export function PlaylistMetadataDialog({ formApi, handleSubmit }: PlaylistMetadataDialogProps) {
    const { control, register, formState, getFieldState } = formApi;

    const [isOpen, setIsOpen] = useState(false);
    const { fields, append, remove } = useFieldArray({ control, name: 'metadata' });
    const userPermissions = useUserPermissions();

    const metadataNumber = fields?.length || 0;

    const toggleDialog = () => setIsOpen(!isOpen);

    const handleSaveClick = async () => {
        await handleSubmit();
        if (!getFieldState('metadata').error) {
            toggleDialog();
        }
    };

    const DialogTrigger = (
        <div>
            <div className={css.triggerButtonContainer}>
                <Button type="button" variant="standard" size="small" onClick={toggleDialog}>
                    Additional properties
                </Button>
                {metadataNumber > 0 && (
                    <div className={css.metadataNumber}>
                        ({metadataNumber} {metadataNumber > 1 ? 'properties' : 'property'} added)
                    </div>
                )}
            </div>
            {Boolean(formState.errors?.metadata) && <InputError>Additional properties are not correct</InputError>}
        </div>
    );

    return (
        <Dialog isOpen={isOpen} heading="Additional properties" onClose={toggleDialog} dialogTrigger={DialogTrigger}>
            <Dialog.Section>
                <div className={css.container}>
                    {!fields?.length && <div className={css.noProperties}>No properties</div>}
                    {fields.map((field, index) => (
                        <div key={field.id} className={css.inputsRow}>
                            <Input
                                {...register(`metadata.${index}.key`)}
                                error={formState.errors?.metadata?.[index]?.key?.message}
                                placeholder="Key"
                                size="small"
                            />
                            <Input
                                {...register(`metadata.${index}.value`)}
                                error={formState.errors?.metadata?.[index]?.value?.message}
                                placeholder="Value"
                                size="small"
                            />
                            <Button size="small" variant="standard" type="button" onClick={() => remove(index)}>
                                Delete
                            </Button>
                        </div>
                    ))}
                    <Button
                        type="button"
                        size="small"
                        className={css.addButton}
                        iconOnly
                        onClick={() => append({ key: '', value: '' })}
                    >
                        <MdAdd />
                    </Button>
                </div>
            </Dialog.Section>
            <Dialog.Section mode="flexRight" variant="darker">
                <Button type="button" variant="standard" onClick={toggleDialog}>
                    Cancel
                </Button>
                <Button type="button" variant="primary" disabled={!userPermissions?.isUser} onClick={handleSaveClick}>
                    Save
                </Button>
            </Dialog.Section>
        </Dialog>
    );
}
