import config from 'config';
import has from 'lodash/has';
import get from 'lodash/get';

export const defaultFilters = {
    status: ['active', 'inactive', 'draft', 'readyToPublish'],
    assetType: ['video', 'audio'],
};

export function prepareFilter(value) {
    return Array.isArray(value) ? value.join(',') : value;
}

export function createFilterString(filters) {
    return Object.entries({ ...defaultFilters, ...filters })
        .reduce((acc, [key, value]) => {
            const filterValue = prepareFilter(value);
            if (!filterValue) {
                return acc;
            }

            if (has(config, `filters.${key}`)) {
                const { label, operator } = get(config, `filters.${key}`);
                return [...acc, `${label}${operator}${filterValue}`];
            }

            return [...acc, `${key}::${filterValue}`];
        }, [])
        .join('|');
}

export function prepareFiltersReverse(filters = '') {
    return filters.split('|').reduce((prev, filter) => {
        const [key, value] = filter.split('::');

        if (key && value) {
            return {
                ...prev,
                [key]: value.split(','),
            };
        }
        if (/duration/.test(key) && filter.includes(config.filters.min.operator)) {
            const minDuration = filter.split(config.filters.min.operator);
            const minDurationValue = minDuration[1];

            return {
                ...prev,
                min: Number(minDurationValue),
            };
        }
        if (/duration/.test(key) && filter.includes(config.filters.max.operator)) {
            const maxDuration = filter.split(config.filters.max.operator);
            const maxDurationValue = maxDuration[1];

            return {
                ...prev,
                max: Number(maxDurationValue),
            };
        }
        if (/categoryId/.test(key) && filter.includes(config.filters.excludedCategory.operator)) {
            const excludedCategory = filter.split(config.filters.excludedCategory.operator);

            return {
                ...prev,
                excludedCategory: excludedCategory[1].split(','),
            };
        }

        if (/additional.tags.id/.test(key) && filter.includes(config.filters.excludedTags.operator)) {
            const excludedTags = filter.split(config.filters.excludedTags.operator);

            return {
                ...prev,
                excludedTags: excludedTags[1].split(','),
            };
        }

        if (/id/.test(key) && filter.includes(config.filters.excludedAssets.operator)) {
            const excludedAssets = filter.split(config.filters.excludedAssets.operator);

            return {
                ...prev,
                excludedAssets: excludedAssets[1].split(',').map(Number),
            };
        }

        return prev;
    }, {});
}
