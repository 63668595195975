/* eslint-disable no-nested-ternary */
import { memo } from 'react';
import PropTypes from 'prop-types';
import { MdAccessTime, MdVideoLibrary, MdSubscriptions } from 'react-icons/md';
import { Spinner } from '@schibsted-svp/react-ui';

import { getFormattedTime } from 'lib/time';
import classnames from 'classnames/bind';
import DeleteConfirmationDialog from 'components/video/Playlist/DeleteConfirmationDialog';
import config from 'config';
import { isLabelInternal } from 'screens/NewsroomScreen/PlaylistsScreen/utils';
import ConditionalLink from 'components/core/ConditionalLink';
import { AssetTile } from './AssetTile';
import PlaylistTile from './PlaylistTile';

import css from './Playlist.module.scss';

const cln = classnames.bind(css);

const { playlists } = config;

const getLabelStyle = (name) => {
    const label = playlists.labels.find((item) => item.label === name);

    return label?.style || {};
};

function Playlist({
    children,
    className,
    hideDeleteButton,
    size,
    linkOnlyOnName,
    showAssetsDisplays,
    playlist: {
        id,
        provider,
        name,
        length,
        duration,
        isFetching,
        assets,
        playlists: popElements,
        locked,
        labels,
        isRegularPlaylist,
    },
    noFollow,
}) {
    const onCopyIdClick = (event) => {
        event.preventDefault();
    };
    const limitDisplayedVideos = 8;

    const labelsWithoutType = labels.filter((label) => !isLabelInternal(label));
    labelsWithoutType.sort((firstLabel, secondLabel) => firstLabel.name.localeCompare(secondLabel.name));

    return (
        <div
            className={cln('playlist', className, `playlist--${size}`, {
                'playlist--not-hover': hideDeleteButton || locked || children,
            })}
        >
            {linkOnlyOnName ? (
                <div className={cln('info', `info--${size}`)}>
                    <span className={css.infoName}>
                        <ConditionalLink to={!noFollow && `/${provider}/playlists/edit/${id}`}>{name}</ConditionalLink>
                    </span>
                    <div className={css.labels}>
                        {labelsWithoutType.map((label) => (
                            <div
                                key={`${id}-${label.id}`}
                                className={cln('label', `label--${size}`)}
                                style={getLabelStyle(label.name)}
                            >
                                {label.name}
                            </div>
                        ))}
                    </div>
                    <span className={css.infoLength}>
                        {isRegularPlaylist ? (
                            <MdVideoLibrary color={css.silver2} />
                        ) : (
                            <MdSubscriptions color={css.silver2} />
                        )}
                        {length}
                    </span>
                    <span className={css.infoDuration}>
                        <MdAccessTime color={css.silver2} /> {getFormattedTime(duration)}
                    </span>
                </div>
            ) : (
                <ConditionalLink
                    to={!noFollow && `/${provider}/playlists/edit/${id}`}
                    className={cln('info', `info--${size}`)}
                >
                    <span className={css.infoName}>{name}</span>
                    <div className={css.labels}>
                        {labelsWithoutType.map((label) => (
                            <div
                                key={label.id}
                                className={cln('label', `label--${size}`)}
                                style={getLabelStyle(label.name)}
                            >
                                {label.name}
                            </div>
                        ))}
                    </div>

                    <span className={css.infoLength}>
                        {isRegularPlaylist ? (
                            <MdVideoLibrary color={css.silver2} />
                        ) : (
                            <MdSubscriptions color={css.silver2} />
                        )}
                        {length}
                    </span>
                    <span className={css.infoDuration}>
                        <MdAccessTime color={css.silver2} /> {getFormattedTime(duration)}
                    </span>
                </ConditionalLink>
            )}

            <div
                className={cln({
                    playlistItems: isRegularPlaylist,
                    playlistItemsList: !isRegularPlaylist,
                    'playlistItems--loading': !length,
                })}
            >
                {isFetching ? (
                    <Spinner containerClassName={css.spinner} />
                ) : isRegularPlaylist ? (
                    assets
                        .slice(0, limitDisplayedVideos)
                        .map(({ asset }) => (
                            <AssetTile
                                provider={asset.provider}
                                assetId={asset.id}
                                showDisplays={showAssetsDisplays}
                                noFollow={noFollow || asset.provider !== provider}
                                onCopyIdClick={onCopyIdClick}
                                key={`regular-${id}-${asset.provider}-${asset.id}`}
                            />
                        ))
                ) : (
                    popElements.map(({ playlist }) => (
                        <PlaylistTile
                            key={`pop-${provider}-${playlist.id}`}
                            id={playlist.id}
                            name={playlist.name}
                            provider={playlist.provider}
                        />
                    ))
                )}
            </div>
            <div className={css.controls}>
                {children || (!hideDeleteButton && !locked && <DeleteConfirmationDialog id={id} provider={provider} />)}
            </div>
        </div>
    );
}

Playlist.propTypes = {
    playlist: PropTypes.shape({
        id: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        searchQuery: PropTypes.string,
        locked: PropTypes.bool.isRequired,
        length: PropTypes.number.isRequired,
        duration: PropTypes.number.isRequired,
        isFetching: PropTypes.bool.isRequired,
        assets: PropTypes.arrayOf(
            PropTypes.shape({
                position: PropTypes.number,
                isStatic: PropTypes.bool,
                asset: PropTypes.object,
            })
        ),
        playlists: PropTypes.arrayOf(
            PropTypes.shape({
                position: PropTypes.number,
                isStatic: PropTypes.bool,
                playlist: PropTypes.object,
            })
        ),
        labels: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                type: PropTypes.string,
            })
        ),

        isRegularPlaylist: PropTypes.bool.isRequired,
    }).isRequired,
    hideDeleteButton: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.oneOf(['normal', 'compact']),
    linkOnlyOnName: PropTypes.bool,
    noFollow: PropTypes.bool,
    showAssetsDisplays: PropTypes.bool,
    children: PropTypes.node,
};

Playlist.defaultProps = {
    hideDeleteButton: false,
    className: '',
    size: 'normal',
    linkOnlyOnName: false,
    noFollow: false,
    showAssetsDisplays: false,
    children: undefined,
};

export default memo(Playlist);
