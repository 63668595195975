import { useState } from 'react';
import type { UseFieldArrayReturn, UseFormGetValues, FormState } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Button, Spinner } from '@schibsted-svp/react-ui';
import StaticItemsSearchDialog from './StaticItemsSearchDialog';
import { RegularPlaylistItem } from './RegularPlaylistItem';
import { PlaylistOfPlaylistItem } from './PlaylistOfPlaylistItem';
import { useFetchPlaylistAssetsViews } from './hooks';
import type { FormValues, PlaylistItemAsset, PlaylistItemPlaylist } from './types';

import css from './PlaylistsFormItems.module.scss';

interface PlaylistsFormItemsProps {
    fieldArrayApi: UseFieldArrayReturn<FormValues, 'items', 'id'>;
    provider: string;
    getValues: UseFormGetValues<FormValues>;
    isRegularPlaylist: boolean;
    isPristine: boolean;
    isPlaylistFetching: boolean;
    disabledSaving: boolean;
    formState: FormState<FormValues>;
    isSearching: boolean;
    pinAssetItem: (index: number) => void;
    removeAssetItem: (index: number) => void;
}

export function PlaylistsFormItems({
    fieldArrayApi,
    provider,
    getValues,
    isRegularPlaylist,
    isPristine,
    isPlaylistFetching,
    disabledSaving,
    formState,
    isSearching,
    pinAssetItem,
    removeAssetItem,
}: PlaylistsFormItemsProps) {
    const { fields, insert, remove } = fieldArrayApi;
    const [isFindModalShown, setIsFindModalShown] = useState(false);
    const { isSubmitting } = formState;

    const [itemPosition, setItemPosition] = useState<number>();

    useFetchPlaylistAssetsViews({ provider, items: fields });

    const { searchQuery } = getValues();

    const dynamicFields = fields.filter((_, index) => fields[index]?.isStatic);

    const isNoSearchResults =
        (searchQuery && (!fields.length || fields.length === dynamicFields.length)) || (!isSubmitting && !isPristine);

    const openAddItemModal = (index: number) => {
        setItemPosition(index);
        setIsFindModalShown(true);
    };

    const moveItem = (fromIndex: number, toIndex: number) => {
        fieldArrayApi.move(fromIndex, toIndex);
    };

    if (isPlaylistFetching || isSearching) {
        return (
            <div className={css.container}>
                <Spinner containerClassName={css.spinner} />
            </div>
        );
    }

    return (
        <div className={css.container}>
            <div className={css.controls}>
                {isNoSearchResults && (
                    <div className={css.noResults}>
                        <FaSearch opacity={0.5} />
                        No dynamic elements matching search criteria
                    </div>
                )}
                <Button type="submit" loading={isSubmitting} disabled={disabledSaving} className={css.saveButton}>
                    Save
                </Button>
            </div>
            {!fields.length ? (
                <div className={css.addVideo} data-testid="addNewVideoView">
                    <Button
                        size="small"
                        className={css.addButton}
                        iconOnly
                        type="button"
                        onClick={() => openAddItemModal(0)}
                    >
                        <MdAdd size={25} />
                    </Button>
                </div>
            ) : (
                <TransitionGroup>
                    {fields.map((field, index) => {
                        const { position } = field;
                        const isLastItem = fields.length === index + 1;

                        return (
                            <CSSTransition key={`${provider}-${field.id}-${position}`} classNames={css} timeout={800}>
                                {isRegularPlaylist ? (
                                    <RegularPlaylistItem
                                        provider={provider}
                                        index={index}
                                        field={field as PlaylistItemAsset}
                                        openAddStaticVideoModal={openAddItemModal}
                                        isLastItem={isLastItem}
                                        moveItem={moveItem}
                                        pinItem={pinAssetItem}
                                        removeItem={removeAssetItem}
                                    />
                                ) : (
                                    <PlaylistOfPlaylistItem
                                        index={index}
                                        field={field as PlaylistItemPlaylist}
                                        openAddItemModal={openAddItemModal}
                                        remove={remove}
                                        moveItem={moveItem}
                                        isLastItem={isLastItem}
                                    />
                                )}
                            </CSSTransition>
                        );
                    })}
                </TransitionGroup>
            )}
            <StaticItemsSearchDialog
                provider={provider}
                isOpen={isFindModalShown}
                setIsOpen={setIsFindModalShown}
                staticItemPosition={itemPosition}
                isRegularPlaylist={isRegularPlaylist}
                getValues={getValues}
                insertItem={insert}
            />
        </div>
    );
}
