import { useCallback, useEffect, useRef } from 'react';
import { Control, useWatch } from 'react-hook-form';
import type { Maybe, Optional } from 'types';
import type { SubtitleCue, SubtitlesFormValues } from '../types';

function areCuesEqual(prevCue: SubtitleCue, nextCue: SubtitleCue) {
    return (
        prevCue.startTime === nextCue.startTime && prevCue.endTime === nextCue.endTime && prevCue.text === nextCue.text
    );
}

function addCue(track: TextTrack, { id, startTime, endTime, text }: SubtitleCue) {
    const cue = new VTTCue(startTime, endTime, text);
    cue.id = id;

    track.addCue(cue);
}

function removeCue(track: TextTrack, { id }: SubtitleCue) {
    const cue = track.cues?.getCueById(id);
    if (cue) {
        track.removeCue(cue);
    }
}

export type CueChangeHandler = (time: Optional<SubtitleCue>) => void;

export function useSubtitlesCues({
    control,
    onCueChange,
}: {
    control: Control<SubtitlesFormValues>;
    onCueChange: CueChangeHandler;
}) {
    const prevCuesRef = useRef(new Map<string, SubtitleCue>());
    const trackRef = useRef<Maybe<TextTrack>>();

    const cues = useWatch({
        control,
        name: 'cues',
    });

    const handleCueChange = useCallback(
        (event: Event) => {
            if (!(event.target instanceof TextTrack) || !event.target.activeCues) {
                return;
            }

            const cue = [...event.target.activeCues].at(-1);
            if (cue instanceof VTTCue) {
                onCueChange({
                    id: cue.id,
                    startTime: cue.startTime,
                    endTime: cue.endTime,
                    text: cue.text,
                });
                return;
            }
            onCueChange(undefined);
        },
        [onCueChange]
    );

    useEffect(() => {
        trackRef.current?.addEventListener('cuechange', handleCueChange);
        return () => {
            trackRef.current?.removeEventListener('cuechange', handleCueChange);
        };
    }, [handleCueChange]);

    useEffect(() => {
        const { current: prevCues } = prevCuesRef;
        const { current: track } = trackRef;
        if (!prevCues || !track) {
            return;
        }

        const cuesIdsToProcess = new Set(prevCues.keys());
        cues.forEach((cue) => {
            const prevCue = prevCues.get(cue.id);

            if (!prevCue) {
                addCue(track, cue);
                prevCues.set(cue.id, cue);
                return;
            }

            if (areCuesEqual(prevCue, cue)) {
                cuesIdsToProcess.delete(cue.id);
                return;
            }

            removeCue(track, cue);
            addCue(track, cue);
            prevCues.set(cue.id, cue);
            cuesIdsToProcess.delete(cue.id);
        });

        cuesIdsToProcess.forEach((id) => {
            const cue = prevCues.get(id);
            if (!cue) {
                return;
            }
            prevCues.delete(id);
            removeCue(track, cue);
        });
    }, [cues]);

    const createCuesTrack = useCallback((video: HTMLVideoElement) => {
        trackRef.current = video.addTextTrack('captions', 'Current', 'en');
        trackRef.current.mode = 'showing';
        trackRef.current.addEventListener('cuechange', handleCueChange);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const flushCues = useCallback(() => {
        const { current: track } = trackRef;
        if (!track) {
            return;
        }
        if (track.mode !== 'showing') {
            track.mode = 'showing';
        }
        if (!track.cues) {
            return;
        }

        // remove cues from last to first to avoid duplication
        for (let i = track.cues.length - 1; i >= 0; i -= 1) {
            const trackCue = track.cues[i];
            track.removeCue(trackCue);
        }

        prevCuesRef.current.clear();
        cues.forEach((cue) => {
            addCue(track, cue);
            prevCuesRef.current.set(cue.id, cue);
        });
    }, [cues]);

    return { createCuesTrack, flushCues };
}
