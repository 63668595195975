import type { Asset } from '@schibsted-svp/svp-api-types';
import { createFilterString } from 'models/filter';
import { svpApiProxy } from './base-api';

type FetchAssetsArgs = {
    provider: string;
    assetId: number[];
    assetType?: string;
    limit?: number;
};

type FetchAssetsResponse = {
    _embedded: {
        assets: Asset[];
    };
};

export const assetsApi = svpApiProxy.injectEndpoints({
    endpoints: (builder) => ({
        fetchAssets: builder.query<Asset[], FetchAssetsArgs>({
            query: ({ provider, assetId, assetType = 'any', limit = 100 }) => ({
                url: `v1/svp/${provider}/assets`,
                params: {
                    filter: createFilterString({ assetId, assetType }),
                    limit,
                },
            }),
            transformResponse: (response: FetchAssetsResponse) => {
                // eslint-disable-next-line no-underscore-dangle
                return response?._embedded?.assets || [];
            },
        }),
    }),
    overrideExisting: false,
});

export const { useFetchAssetsQuery } = assetsApi;
