import isBoolean from 'lodash/isBoolean';
import { defaultFilters, getPreferredPlaylistType } from 'screens/NewsroomScreen/PlaylistsScreen/utils';

import omit from 'lodash/omit';

import { removeEmptyValues } from 'store/asset-list/reducers';

export const PLAYLISTS_FILTERS_SET = 'Playlists/FILTERS_SET';
export const PLAYLISTS_FILTERS_OVERRIDE = 'Playlists/FILTERS_OVERRIDE';

export const PLAYLISTS_FILTERS_SET_CONTROLLER = 'Playlists/FILTERS_SET_CONTROLLER';
export const PLAYLISTS_FILTERS_REMOVE = 'Playlists/FILTERS_REMOVE';
export const PLAYLISTS_FILTERS_CLEAR = 'Playlists/FILTERS_CLEAR';

const playlistDefaultFilters = (newsroom, filters = {}) => ({
    ...defaultFilters(getPreferredPlaylistType()),
    ...filters,
    newsrooms: [newsroom],
});

export const playlistsFiltersSet = ({ filters }) => ({
    type: PLAYLISTS_FILTERS_SET,
    payload: {
        filters,
    },
});

export const playlistsFiltersOverride = ({ filters }) => ({
    type: PLAYLISTS_FILTERS_OVERRIDE,
    payload: {
        filters,
    },
});

export const playlistsFiltersSetController = ({ provider, playlistId }) => ({
    type: PLAYLISTS_FILTERS_SET_CONTROLLER,
    payload: {
        provider,
        playlistId,
    },
});

export const playlistsFiltersRemove = ({ filter, value, newsroom }) => ({
    type: PLAYLISTS_FILTERS_REMOVE,
    payload: {
        filter,
        value,
        newsroom,
    },
});

export const playlistsFiltersClear = (provider, filters = {}) => ({
    type: PLAYLISTS_FILTERS_CLEAR,
    payload: { provider, filters },
});

const REQUIRED_STATUS_FILTER_KEY = 'statuses';

export default function reducer(state = defaultFilters('video'), action) {
    switch (action.type) {
        case PLAYLISTS_FILTERS_SET: {
            const { filters } = action.payload;
            const newState = { ...state };

            return removeEmptyValues({ ...newState, ...filters });
        }

        case PLAYLISTS_FILTERS_OVERRIDE: {
            const { filters } = action.payload;
            return filters;
        }

        case PLAYLISTS_FILTERS_REMOVE: {
            const { filter, value, newsroom } = action.payload;

            const newState = { ...state };

            if (newState?.customFields && Object.keys(newState.customFields).includes(filter)) {
                return {
                    ...newState,
                    customFields: omit(newState.customFields, filter),
                };
            }

            if (isBoolean(value)) {
                return {
                    ...state,
                    [filter]: false,
                };
            }

            if (filter === 'categories' && newState?.customFields) {
                delete newState.customFields;
            }

            const newFilter = ['after', 'before', 'min', 'max', 'categories', 'createdBy'].includes(filter)
                ? null
                : newState[filter].filter((item) => (typeof item === 'object' ? item.value !== value : item !== value));

            if (!newFilter || newFilter.length === 0) {
                if (filter === 'newsrooms') {
                    return {
                        ...newState,
                        newsrooms: [newsroom],
                    };
                }
                return omit(newState, [filter]);
            }

            newState[filter] = newFilter;

            return newState;
        }

        case PLAYLISTS_FILTERS_CLEAR: {
            return playlistDefaultFilters(action.payload.provider, action.payload.filters);
        }

        default: {
            if (!Object.keys(state).includes(REQUIRED_STATUS_FILTER_KEY)) {
                return { ...state, [REQUIRED_STATUS_FILTER_KEY]: defaultFilters()[REQUIRED_STATUS_FILTER_KEY] };
            }
            return state;
        }
    }
}
