import { useSelector } from 'react-redux';
import { getNewsroomConfig } from 'config';
import { useFetchAssetsQuery } from 'services/svp-api-proxy-rtk/assets';
import { getAsset } from 'store/assets/selectors';
import type { PlaylistItem } from './types';

export function usePlaylistDuration({
    items,
    isRegularPlaylist,
}: {
    items: PlaylistItem[];
    isRegularPlaylist: boolean;
}) {
    const playlistAssets = useSelector((state) =>
        items.map((item) => 'asset' in item && getAsset(state, { id: item.asset.id, provider: item.asset.provider }))
    );

    if (!isRegularPlaylist) {
        return 0;
    }

    return playlistAssets.reduce((accumulator, currentAsset) => accumulator + Number(currentAsset?.duration) || 0, 0);
}

export function useFetchPlaylistAssetsViews({
    provider,
    items,
    skip,
}: {
    provider: string;
    items: PlaylistItem[] | undefined;
    skip?: boolean;
}) {
    const { features } = getNewsroomConfig(provider);

    const assetsIds = items
        ?.reduce<number[]>((acc, item) => {
            if ('asset' in item && item.asset.provider === provider) {
                return [...acc, item.asset.id];
            }
            return acc;
        }, [])
        ?.sort();

    useFetchAssetsQuery(
        { provider, assetId: assetsIds },
        { skip: skip || !features.playlistViews?.enabled || !assetsIds?.length }
    );
}
